<div class="relative">
  <div class="error-message fixed inset-x-0 top-0 z-50 flex justify-center" id="error-message">
    <div role="alert" class="alert alert-error bg-red-500 text-white p-4 rounded-lg shadow-lg flex items-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6 shrink-0 stroke-current mr-2"
        fill="none"
        viewBox="0 0 24 24">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
      <span>mail eller password var forkert.</span>
    </div>
  </div>
  
  <div class="background bg-no-repeat bg-cover bg-center relative">
    <div class="absolute bg-gradient-to-b from-blue-500 to-blue-400 opacity-50 inset-0 z-0"></div>
    <div class="min-h-screen sm:flex sm:flex-row mx-0 justify-center">
      <div *ngIf="isLoginStateLogin" class="login-form flex justify-center self-center z-10">
        <div class="p-12 bg-white mx-auto rounded-2xl w-100 ">
          <div class="mb-4">
            <h3 class="font-semibold text-2xl text-gray-800">Log ind </h3>
          </div>
          <div class="space-y-5">
            <div class="space-y-2">
              <div class="text-sm font-medium text-gray-700 tracking-wide">Email</div>
              <div>
                <input placeholder="din-mail@gmail.com" type="email" id="email" required class="w-full text-base px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-400">
              </div>
            </div>
            <div class="space-y-2">
              <div class="mb-5 text-sm font-medium text-gray-700 tracking-wide">Password</div>
              <input (keydown.enter)="login()" type="password" id="password" required class="w-full content-center text-base px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-400" placeholder="Skriv dit password">
            </div>
            <div class="flex items-center justify-between">
              <div class="text-sm">
                <a (click)="updateLoginState('forgotPassword')" class="text-blue-400 hover:text-blue-500">Glemt password</a>
              </div>
              <div class="text-sm">
                <a (click)="updateLoginState('createUser')" class="text-blue-400 hover:text-blue-500">Lav ny bruger</a>
              </div>
            </div>
            <div>
              <button (click)="login()" type="button" class="w-full flex justify-center bg-blue-400 hover:bg-blue-500 text-gray-100 p-3 rounded-full tracking-wide font-semibold shadow-lg cursor-pointer transition ease-in duration-500">
                Log ind
              </button>
          </div>
        </div>
      </div>
    </div>
    
    <div *ngIf="isLoginStateForgotPassword" class="login-form flex justify-center self-center z-10">
      <div class="p-12 bg-white mx-auto rounded-2xl w-100 ">
        <div class="mb-4">
          <h3 class="font-semibold text-2xl text-gray-800">Glemt Login</h3>
        </div>
          <div class="space-y-5">
            <div class="space-y-2">
              skriv til os på: {{contactEmail}}
            </div>
            <div class="space-y-2">
              eller i chatten (bunden til højre)
            </div>
        </div>
      </div>
      <a (click)="toLogin()" class="btn btn-sm md:btn-md gap-2 lg:gap-3"><svg class="h-6 w-6 fill-current md:h-8 md:w-8" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"></path></svg> <div class="flex flex-col items-start"><span>Tilbage</span></div></a>
    </div>

    <div *ngIf="isLoginStateCreateUser" class="login-form flex justify-center self-center z-10">
      <div class="p-12 bg-white mx-auto rounded-2xl w-100 ">
        <div class="mb-4">
          <h3 class="font-semibold text-2xl text-gray-800">Lav Bruger</h3>
        </div>
          <div class="space-y-5">
            <div class="space-y-2">
              skriv til os på: {{contactEmail}}
            </div>
            <div class="space-y-2">
              eller i chatten (bunden til højre)
            </div>
        </div>
      </div>
      <a (click)="toLogin()" class="btn btn-sm md:btn-md gap-2 lg:gap-3"><svg class="h-6 w-6 fill-current md:h-8 md:w-8" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"></path></svg> <div class="flex flex-col items-start"><span>Tilbage</span></div></a>
    </div>
  </div>
</div>