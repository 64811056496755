import {Inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ENV} from "../../../../environments/environment.provider";
import {Environment} from "../../../../environments/ienvironment";
import {Observable} from "rxjs";
import { GetUserResponse } from "./get-user-response";
import { AuthenticateService } from "../auth/authenticate.service";

@Injectable({
  providedIn: 'root'
})
export class UserService{

  constructor(
    private http: HttpClient,
    @Inject(ENV) private env: Environment,
    private authService: AuthenticateService
  ) {
  }

  GetCurrentUser(): Observable<GetUserResponse> {
    const userId = this.authService.getUserId();
    return this.http.get<GetUserResponse>(`${this.env.sikrApiUrl}/api/v1/users/${userId}`);
  }
}
