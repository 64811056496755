import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LayoutComponent} from "./layout/layout.component";
import {NotFoundComponent} from "./not-found/not-found.component";
import {OnboardingComponent} from "./onboarding/onboarding.component";
import {LoginComponent} from "./login/login.component";
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  {
    path: 'auth',
    pathMatch: 'prefix',
    children: [
      {path: 'login', component: LoginComponent,}
    ]
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {path: '', component: OnboardingComponent}
    ],
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    pathMatch: 'full',
    component: LayoutComponent,
    children: [
      {path: '', component: NotFoundComponent}
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    anchorScrolling: 'enabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
