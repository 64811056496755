import {Component} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from '@angular/common';
import { UserService } from '../core/services/user/user.service';
import { AuthenticateService } from '../core/services/auth/authenticate.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css'],
})
export class LayoutComponent {
  isRoot = false;
  showUserMenu = false;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly userService: UserService,
    private readonly authService: AuthenticateService,
    private readonly router: Router,
    private readonly location: Location
  ) {
    this.isRoot = this.router.url == '/';
    this.setCircleColor();
  }

  setCircleColor() {
    this.userService.GetCurrentUser().subscribe({
        next: response => {
          const charCode1 = response.firstName.charCodeAt(0);
          const charCode2 = response.lastName.charCodeAt(0);

          // Generate random color based on char codes (within valid hex color range)
          const red = (charCode1 * 53) % 256;
          const green = (charCode2 * 71) % 256;
          const blue = ((charCode1 + charCode2) * 37) % 256;

          const circle = document.getElementById('colorCircle');
          if (circle) {
            circle.innerText = response.firstName.charAt(0)+response.lastName.charAt(0);
            circle.style.backgroundColor = `rgb(${red}, ${green}, ${blue})`;
          }
        }
      }
    );
  }

  toggleShowUserMenu(){
    this.showUserMenu = !this.showUserMenu;
  }

  logout(){
    this.authService.logout();
    this.router.navigate(['/auth/login']);
  }

  goBack() {
    if (!this.isRoot) {
      // We can safely go back to the previous location as
      // we know it's within our app.
      this.location.back();
    } else {
      // There's no previous navigation.
      // Here we decide where to go. For example, let's say the
      // upper level is the index page, so we go up one level.
      this.router.navigate(['/']);
    }
  }

}

export interface NavigationItem {
  displayName: string
  url: string
}
