import { Component } from '@angular/core';
import { AuthenticateService } from '../core/services/auth/authenticate.service';
import { AuthenticateRequest } from '../core/services/auth/authenticate.request';
import { Router } from '@angular/router';
import { LoginState } from './types/login.state.enum';
import { NgIf } from "@angular/common";

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    NgIf
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css',
})
export class LoginComponent {
  contactEmail = 'kontakt@sikr.io'
  errorMessage: HTMLElement | null = null;
  loginState: LoginState = LoginState.login
  isLoginStateLogin = true;
  isLoginStateCreateUser = false;
  isLoginStateForgotPassword = false;
  authenticateRequest: AuthenticateRequest = {
    email: '',
    password: ''
  };

  constructor(private authenticateService: AuthenticateService, private router: Router) {
    // eslint-disable-next-line
    (event: KeyboardEvent) => {
      const { key } = event
        if (key === "Enter") {
          this.login();
        }
    }
  }

  login(): void {
    const email = document.getElementById('email') as HTMLInputElement;
    const password = document.getElementById('password') as HTMLInputElement;

    this.authenticateRequest.email = email.value ?? "";
    this.authenticateRequest.password = password.value ?? "";

    this.authenticateService.authenticateUser(this.authenticateRequest).subscribe(
      () => {
        this.router.navigate(['/']);
      },
      error => {
        const errorMessage = document.getElementById('error-message') as HTMLInputElement;
        console.error('Login failed', error);
        setTimeout(() => { errorMessage.style.display = "none" }, 5000);
        errorMessage.style.display = "flex"
      }
    );
  }


  toLogin(): void {
    this.isLoginStateLogin = true;
    this.isLoginStateCreateUser = false;
    this.isLoginStateForgotPassword = false;
  }

  updateLoginState(loginState: string){
    this.loginState = loginState as LoginState;

    this.isLoginStateLogin = false;
    this.isLoginStateCreateUser = false;
    this.isLoginStateForgotPassword = false;

    switch(this.loginState){
      case LoginState.login:
        this.isLoginStateLogin = true;
        break;
      case LoginState.createUser:
        this.isLoginStateCreateUser = true;
        break;
      case LoginState.forgotPassword:
        this.isLoginStateForgotPassword = true;
        break;
    }
  }
}
